export const LOCAL = {
  global: {
    aid: 12655,
    hostUrl: 'https://chat.eyelevel.ai',
    partnerId: 2,
    splash: 'https://www.chatessential.com',
  },
  local: {
    baseUrl: 'https://localhost:3000',
    cognito: {
      // Amazon Cognito Region
      region: 'us-west-2',
      // Amazon Cognito User Pool ID
      userPoolId: 'us-west-2_0N5RphwJX',
      // Amazon Cognito Web Client ID
      userPoolWebClientId: '7m4u3q2u8k49lh7gsk1dq8lu3q',
    },
    plans: {
      name: 'STANDARD PLAN',
      month: {
        id: 'price_1IdLA3BxgG518sDHlwUQmr2k',
        amount: 2000,
        currency: 'usd',
      },
      GPT3: {
        id: 'price_1JV3nRBxgG518sDHKknvHkxD',
        amount: 2000,
        currency: 'usd'
      },
      wordPress: {
        id: 'price_1JV3prBxgG518sDHu8EAkMnV',
        amount: 2000,
        currency: 'usd'
      },
      year: {
        id: 'price_1IdLAWBxgG518sDHhh2eh1yk',
        amount: 20000,
        currency: 'usd',
      },
    },
    redirect_uri: 'https://localhost:3000/auth',
    template: {
      FlowName: '01FCGVSH15HGNKSERPA7RZFJCT',
    },
  },
  dev: {
    baseUrl: 'https://devssp.eyelevel.ai',
    cognito: {
      // Amazon Cognito Region
      region: 'us-west-2',
      // Amazon Cognito User Pool ID
      userPoolId: 'us-west-2_0N5RphwJX',
      // Amazon Cognito Web Client ID
      userPoolWebClientId: '3k9lk0jb3e47vcu5i5doamurgs',
    },
    plans: {
      name: 'STANDARD PLAN',
      month: {
        id: 'price_1IdLA3BxgG518sDHlwUQmr2k',
        amount: 2000,
        currency: 'usd',
      },
      GPT3: {
        id: 'price_1JV3nRBxgG518sDHKknvHkxD',
        amount: 2000,
        currency: 'usd'
      },
      wordPress: {
        id: 'price_1JV3prBxgG518sDHu8EAkMnV',
        amount: 2000,
        currency: 'usd'
      },
      year: {
        id: 'price_1IdLAWBxgG518sDHhh2eh1yk',
        amount: 20000,
        currency: 'usd',
      },
    },
    redirect_uri: 'https://devssp.eyelevel.ai/auth',
    template: {
      FlowName: '01FCGVSH15HGNKSERPA7RZFJCT',
    },
  },
  prod: {
    baseUrl: 'https://chatessential.eyelevel.ai',
    cognito: {
      // Amazon Cognito Region
      region: 'us-west-2',
      // Amazon Cognito User Pool ID
      userPoolId: 'us-west-2_0N5RphwJX',
      // Amazon Cognito Web Client ID
      userPoolWebClientId: '4hcm9bpll99bd3f29dpu1kdins',
    },
    plans: {
      name: 'STANDARD PLAN',
      month: {
        id: 'price_1IdM7NBxgG518sDHLylNPS2u',
        amount: 2000,
        currency: 'usd',
      },
      GPT3: {
        id: 'price_1JV3rNBxgG518sDHznYb7mUF',
        amount: 2000,
        currency: 'usd'
      },
      wordPress: {
        id: 'price_1JV3r1BxgG518sDHjqdNmbdn',
        amount: 2000,
        currency: 'usd'
      },
      year: {
        id: 'price_1IdM7bBxgG518sDH59Cr5PMU',
        amount: 20000,
        currency: 'usd',
      },
    },
    redirect_uri: 'https://chatessential.eyelevel.ai/auth',
    template: {
      FlowName: '01FCGW48VTQ9SE94087YFK2JTA',
    },
  },
};
