import React from 'react';

import BoldSpan from '../components/common/BoldSpan';
import theme from '../theme';
import { getNext, getPrev, startSteps, steps } from './onboardingSteps';
import { grabOnboardingInfo } from '../utils';

export const hideStart = {};
hideStart[startSteps.INTRO] = true;
hideStart[startSteps.LIVECHAT] = false;
hideStart[startSteps.PLATFORM] = true;
hideStart[startSteps.HOSTING] = true;
hideStart[startSteps.WEBSITE] = false;
hideStart[startSteps.TEMPLATES] = true;
hideStart[startSteps.TRAINAI] = false;

export const startMap = {};
startMap[startSteps.LIVECHAT] = steps.ConnectChat;
startMap[startSteps.WEBSITE] = steps.ConnectWeb;
startMap[startSteps.TRAINAI] = steps.TrainAI;

export const theseSteps = {
  ThankYouTrial:         steps.ThankYouTrial,
  TrialStarted:          steps.TrialStarted,
  StartTour:             steps.StartTour,
};

export function getNextStep(step) {
  return getNext(step, theseSteps);
}

export function getPrevStep(step) {
  return getPrev(step, theseSteps);
}

const nextShow = (step) => {
  const items = Object.keys(hideStart).map(function(key) {
    return [key, hideStart[key]];
  });

  items.sort(function(first, second) {
    return first[0] - second[0];
  });

  let checkNext = false;
  if (step === 0) {
    checkNext = true;
  }
  for (var i = 0; i < items.length; i++) {
    const stepNum = parseInt(items[i][0]);
    if (stepNum === step) {
      checkNext = true;
      if (!items[i][1]) {
        return stepNum;
      }
    } else if (checkNext) {
      if (!items[i][1]) {
        return stepNum;
      }
    }
  }
  return 0;
};

const prevShow = (step) => {
  const items = Object.keys(hideStart).map(function(key) {
    return [key, hideStart[key]];
  });

  items.sort(function(first, second) {
    return first[0] - second[0];
  });

  let lastShow = 0;
  for (var i = 0; i < items.length; i++) {
    const stepNum = parseInt(items[i][0]);
    if (stepNum === step) {
      if (!items[i][1]) {
        return stepNum;
      }
      return lastShow;
    } else if (lastShow && stepNum > step) {
      return lastShow;
    } else if (!items[i][1]) {
      lastShow = stepNum;
    }
  }

  return lastShow;
};

export const defaultPlatform = 'web';

export const finalStepID = steps.StartTour;

export const onboardingCopy = {
  getStarted: {
    title: (<>Launch {theme.article} chat in <BoldSpan>TWO</BoldSpan> steps.</>),
  }
};

// The last step where nav is inactive
export const navActiveStep = steps.StartTour;

export function nextShowStep(setShow, step) {
  if (setShow === startSteps.INIT) {
    if (step === steps.ChooseTemplate) {
      return startSteps.TEMPLATES;
    } else if (step === steps.ConnectWeb) {
      return startSteps.WEBSITE;
    } else if (step === steps.TrainAI) {
      return startSteps.TRAINAI;
    }
    setShow = startSteps.INTRO;
  }

  return nextShow(setShow);
}

export function prevShowStep(setShow, step) {
  if (setShow === startSteps.INIT) {
    setShow = startSteps.INTRO;
  }

  return prevShow(setShow);
}

export function startTrial() {
  const onboardingInfo = grabOnboardingInfo();

  return onboardingInfo?.subscriptions;
};

// Set to null to use defaults
export const startButton = null;
export const isTour = null;