import React from 'react';

import { Text } from '../components/AI/Common/Common';

import { hasAIModel, initialSetupComplete } from '../utils/subscriptions';

export const redirectUrls = {};

export const userOnboardingTypes = {
  AI_ONBOARDING:        'aiOnboarding',
  DEFAULT_ONBOARDING:   'defaultOnboarding',
  WEB_CRAWL_ONBOARDING: 'webCrawlOnboarding',
  WORDPRESS_ONBOARDING: 'wordPressOnboarding',
};

export const THEME = {
  article: 'a Chat Essential',
  bubble: 'https://cdn.cashbot.ai/assets/chat_bubble_large-qr.svg',
  calendar: 'https://meeting.calendarhero.com/meeting/new/633ee5e819788b00137cd9cb/meeting',
  customization: {
    title: 'CHAT',
  },
  defaultContentLimit: 4,
  defaultPlan: {
    Aside: 'You are on the basic plan and have limited access to features.',
    Name: 'BASIC PLAN',
    CTA: 'UPGRADE PLAN',
    Type: 'restart',
  },
  help: 'https://help.eyelevel.ai',
  login: '/demo',
  logo: 'https://cdn.cashbot.ai/qr-assets/chat-essential-site-logo-with-tag.svg',
  name: 'Chat Essential',
  nav: {
    hideChat: true,
    hideHelp: true,
    hideLogin: true,
    hideLogout: true,
    ignoreOnboarding: ['78ec21d6-4b9e-4134-8701-5ce963261041'],
  },
  partner_username: '6beda510-b55e-4d98-b9a8-5fb16fe9dd7b',
  pricing: 'https://resources.eyelevel.ai/pricing',
  privacy: 'https://eyelevel.ai/privacy-policy',
  showSignup: function() {
    return false;
  },
  signUp: {
    cta: 'Need an account?',
    icon: 'https://cdn.cashbot.ai/assets/cartoon.kiss.svg',
    thankYouIcon: 'https://cdn.cashbot.ai/assets/cartoon.snap.svg',
    title: 'Create an account.',
  },
  skipPayments: true,
  support: 'support@eyelevel.ai',
  terms: 'https://eyelevel.ai/terms-of-use',
  trialDays: 14,
  modals: {
    gpt3: {
      button: `BUILD MY VIRTUAL ASSISTANT`,
      gpt3Icon: 'https://cdn.cashbot.ai/qr-assets/23_gpt3 onboarding_icon_gpt3.svg',
      header:`Turn a URL into a powerful ChatGPT assistant in 1 click`,
      hideWordPress: true,
      text: `Continue with our virtual assistant quickstart.`,
    },
    welcome: {
      account: {
        l1: { show: true },
        l2: { show: true },
        l3: { show: true },
        l4: { show: false },
        l5: { show: true },
        l6: { show: false },
      },
      dashboard: {
        duplicate:
          'Save time by duplicating flows. You can publish any duplicated flow to any platform.',
        publish: 'Access the publish information for particular flows in one click',
      },
    },
  },
  pages: {
    account: {
      hideAddAccounts: true,
      hideAddHours: true,
      hideAddQRThemes: true,
      hideAddThemes: true,
      hideProfile: true,
      hideWebPages: true,
      showSMSCard: true,
    },
    ai: {
      help: {
        textNotEnough: `Our AI needs a minimum amout of content about your business to create a meaningful conversational experience for your users. 
        Please check that the url is correct, or add more urls below. Other useful content could include blogs and articles about your company on other websites.`
      },
      startPage: {
        header: 'Virtual Assistant Quickstart',
        textHeader: 'Just enter a website url!',
        text: `Enter your marketing website url and we'll create an AI-powered virtual assistant that can talk about the content on the pages.`,
        subtext: (<Text>Click <b>TRAIN ASSISTANT</b> to start the process.</Text>),
        button: 'TRAIN ASSISTANT',
        vanityIcon: 'https://cdn.cashbot.ai/qr-assets/23_illustration 1.svg',
      },
      creationPage: {
        header: 'Love your new assistant?',
        text: `Start a subscription and claim your virtual assistant that will
        launch from your website!`,
        title: 'Virtual Assistant Demo',
        button: `SCHEDULE A CONSULTATION`,
        type: 'vendasta-scheduler',
        error: {
          button: 'SCHEDULE A CONSULTATION',
          header: 'Issues creating an assistant?',
          header2: 'Let us help!',
          icon: 'https://cdn.cashbot.ai/qr-assets/23_gpt3 onboarding_error guy.svg',
          text: `Sometimes there are issues with crawling certain types of websites.
          Our team can help you resolve these issues.`,
        }
      }
    },
    graph: {
    },
    payment: {},
    wordpress: {},
  },
};

/**
 * @param {Object} resource Onboarding information about the user.
 * @return {bool} True if user has subscription, otherwise false.
 */
export const grantAccess = (resource, sessionState) => {
  switch (resource) {
    case 'add-off-hours':
      return sessionState?.length === 0;
    case 'add-qr-theme':
      return sessionState?.length === 0;
    case 'add-theme':
      return sessionState?.length === 0;
    case 'admin-signup':
      return sessionState?.AccountInfo?.AccessLevel >= 20;
    case 'dashboard-create-group-button':
      if (sessionState?.AccountInfo && sessionState?.Content) {
        const content = sessionState.Content.filter((cn) => {
          return cn?.Purpose === 'group';
        });
        const dflt = sessionState?.AccountInfo?.GroupLimit ? sessionState.AccountInfo.GroupLimit :
          THEME?.defaultGroupLimit ? THEME.defaultGroupLimit : 2;
        if (sessionState?.AccountInfo?.GroupLimit === 0 || content.length < dflt) {
          return true;
        }
      }
      return 'hidden';
    case 'new-project':
    case 'dashboard-create-project-button':
      if (sessionState?.AccountInfo && sessionState?.Subscriptions) {
        const dflt = sessionState?.AccountInfo?.ProjectLimit ? sessionState.AccountInfo.ProjectLimit : -1;
        if (sessionState?.AccountInfo?.ProjectLimit === 0 || sessionState.Subscriptions?.length < dflt) {
          return true;
        }
      }
      return 'hidden';
    case 'dashboard-create-content-button':
      if (sessionState?.AccountInfo && sessionState?.Content) {
        const content = sessionState.Content.filter((cn) => {
          return cn?.Purpose !== 'group';
        });
        const dflt = sessionState?.AccountInfo?.ContentLimit ? sessionState.AccountInfo.ContentLimit :
          THEME?.defaultContentLimit ? THEME.defaultContentLimit : 4;
        if (sessionState?.AccountInfo?.ContentLimit === 0 || content.length < dflt) {
          return true;
        }
      }
      return 'disabled';
    case 'delete-qr-theme':
      return false;
    case 'delete-theme':
      return false;
    case 'flow-change-group':
      return true;
    case 'nav-account':
      if ((sessionState?.subscriptions?.length && initialSetupComplete(sessionState.subscriptions)) || []) {
        return true;
      }
      break;
    case 'nav-ai':
      if (hasAIModel(sessionState?.subscriptions || [])) {
        return true;
      }
      break;
    case 'nav-dashboard':
      if (sessionState?.subscriptions?.length && initialSetupComplete(sessionState.subscriptions)) {
        if (sessionState?.subscriptions?.length && initialSetupComplete(sessionState.subscriptions )) {
          return true;
        }
      }
      break;
    default:
      return false;
  }
};

export const checkOnboardingUserType = (resource, userType) => {
  if (resource.demoUUID) {
    if (userType === userOnboardingTypes.WEB_CRAWL_ONBOARDING) {
      return true;
    } else if (userType === userOnboardingTypes.AI_ONBOARDING) {
      return true;
    }
  } else if (userType === userOnboardingTypes.DEFAULT_ONBOARDING) {
    return true;
  }

  return false;
};

export const toolTipForDisabled = resource => {
  return 'Disabled for users with basic subscription';
};
