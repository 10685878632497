export const prod = Object.freeze({
  QRCODE: 'qr',
  WEBSITE: 'web',
});

export const beta = Object.freeze({
});

export const integrations = Object.freeze({
  HUBSPOT: 'hubspot',
  MAILCHIMP: 'mailchimp',
  MARKETO: 'marketo',
  SALESFORCE: 'salesforce',
  SLACK: 'slack',
  SMS: 'mms',
  TEAMS: 'msteams',
  ZAPIER: 'zapier',
});

export const integrationDesc = Object.freeze({
  FACEBOOK: {
    type: 'Marketing',
    isPremium: true,
    label: 'Facebook Ad (Premium)',
    value: integrations.FACEBOOK,
  },
  LINKEDIN: {
    type: 'Marketing',
    isPremium: true,
    label: 'LinkedIn (Premium)',
    value: integrations.LINKEDIN,
  },
  TEAMS: {
    type: 'Live Chat',
    isPremium: true,
    label: 'Microsoft Teams (Premium)',
    value: integrations.TEAMS,
  },
  SLACK: {
    type: 'Live Chat',
    label: 'Slack',
    value: integrations.SLACK,
  },
  SMS: {
    type: 'Live Chat',
    isPremium: false,
    label: 'SMS',
    value: integrations.SMS,
  },
  HUBSPOT: {
    type: 'CRM',
    isPremium: true,
    label: 'HubSpot (Premium)',
    value: integrations.HUBSPOT,
  },
  MAILCHIMP: {
    type: 'CRM',
    isPremium: true,
    label: 'Mailchimp (Premium)',
    value: integrations.MAILCHIMP,
  },
  MARKETO: {
    type: 'CRM',
    isPremium: true,
    label: 'Marketo (Premium)',
    value: integrations.MARKETO,
  },
  SALESFORCE: {
    type: 'CRM',
    isPremium: true,
    label: 'Salesforce (Premium)',
    value: integrations.SALESFORCE,
  },
  ZAPIER: {
    type: 'CRM',
    isPremium: true,
    label: 'Zapier (Premium)',
    value: integrations.ZAPIER,
  },
  FACEBOOKPAGE: {
    type: 'Live Chat',
    isPremium: true,
    label: 'Facebook Page (Premium)',
    value: integrations.FACEBOOKPAGE,
  },
});